@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200..1000;1,200..1000&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap');

/* header css */
/* *{
    font-family: "Nunito", sans-serif!important;
} */
/* body{
    font-family: "Nunito", sans-serif!important;
} */
.Manrope {
    font-family: "Manrope", sans-serif;
}

.banner {
    font-family: "Nunito", sans-serif !important;
    /* margin-top: 1rem; */
}

.lato-regular {
    font-family: "Lato", sans-serif;
    font-weight: 400;
    font-style: normal;
}

.lato-bold {
    font-family: "Lato", sans-serif;
    font-weight: 700;
    font-style: normal;
}


.open_header a {
    /* padding-left: 2.5rem;
    padding-right: 2.5rem; */
    padding-left: 2rem;
    padding-right: 2rem;
    font-size: 1rem;
    font-weight: 600;
    margin-inline-start: 0 !important;
    font-family: "Nunito", sans-serif !important;
}

.open_header p {
    /* padding-left: 2.5rem;
    padding-right: 2.5rem; */
    padding-left: 2rem;
    padding-right: 2rem;
    font-size: 1rem;
    font-weight: 600;
    margin-inline-start: 0 !important;
    font-family: "Nunito", sans-serif !important;
    cursor: pointer;
}

.facts {
    font-family: "Nunito", sans-serif !important;
}

.figures {
    font-family: "Outfit", sans-serif !important;
    font-weight: 600;
}

.image-text {
    font-family: "Outfit", sans-serif !important;
}

.main-section {
    font-family: "Nunito", sans-serif !important;
}

.footer {
    font-family: "Nunito", sans-serif !important;
}

.footer-FAQ {
    font-family: "Nunito", sans-serif !important;
}

/* .active {
    color: red !important;
} */
.leftDivider::after {
    position: absolute;
    content: '';
    right: 0;
    height: 20px;
    width: 2px;
    background-color: #000;
}

.head_icon svg {
    display: inline-block;
}

.text_deco {
    font-family: "Nunito", sans-serif !important;
}

.text_deco text {
    font-family: "Outfit", sans-serif !important;
}

/* .logo_link {
    font-family: "Nunito", sans-serif!important;
} */

.text_deco a:hover,
.logo_link a:hover {
    text-decoration: underline !important;
}

.open_header a:hover {
    border-bottom: 3px solid #FB1923;
    color: #FB1923;
    font-size: 1rem;
    font-weight: 600;
}

.open_header p:hover {
    border-bottom: 3px solid #FB1923;
    color: #FB1923;
    font-size: 1rem;
    font-weight: 600;
}

.open_header a {
    border-bottom: none;
}

.open_header p {
    border-bottom: none;
}

.open_header a.active {
    /* border-bottom: 10px solid #FB1923; */
    color: #FB1923;
}

.open_header a {
    border-bottom: solid 3px transparent;
}

.open_header p {
    border-bottom: solid 3px transparent;
}

header svg path {
    stroke: white;
}

.menubtn {
    color: #323232;
    font-weight: 400;
    font-size: 16px;
    font-family: "Nunito", sans-serif !important;
}

.menubtn:hover {
    color: #FB1923;
    background-color: #fff;
}

.Home_tabs_btn .css-1ss3utg {
    padding: 1rem 4rem;
    font-size: medium;
    /* font-weight: lighter; */
    font-weight: 400;
    background-color: white;
    border-radius: 5px;
    font-family: "Nunito", sans-serif !important;
}

.mtn {
    display: none;
}

.mty:hover .mtn {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(214, 94, 100, 0.52);
    align-items: center;
    z-index: 11;
    /* transform: translateX(-50%); */
}

.work_tabs input {
    width: 100%;
    cursor: pointer;
}

.work_btn .css-52dxnr {
    width: 13rem !important;
    margin-top: 1rem !important;
}

.work_btn .css-52dxnr[aria-selected=true],
.work_btn .css-52dxnr[data-selected] {
    color: #FF1111;
    border-bottom: none !important;
}

.css-1ss3utg[aria-selected=true],
.css-1ss3utg[data-selected] {
    box-shadow: rgb(255 187 187) -5px 16px 49px -14px;
    border-radius: 5px;
    background-color: white !important;
}

.gallery_slide .slick-list {
    /* width: 90%;
    margin: auto; */
    text-align: center;
}

.gallery_slide img {
    max-width: 20rem;
    min-width: 15rem;
    margin: auto;
}

.slick-prev:hover,
.slick-next:hover {
    background-color: rgba(255, 17, 17, 1) !important;
    color: #fff !important;
}

.slick-prev,
.slick-next {
    box-sizing: border-box;
    transition: all 0.3s ease 0s;
    background-color: 251 25 35 / 12% !important;
    color: rgb(153, 153, 153) !important;
    box-shadow: rgb(51 51 51) 0px 0px 2px 0px !important;
    border-radius: 50%;
    border: none;
    padding: 0px;
    width: 50px !important;
    height: 50px !important;
    min-width: 50px !important;
    line-height: 50px !important;
    align-self: center;
    cursor: pointer;
    outline: none;
}

.slick-dots li.slick-active button:before {
    color: red !important;
}

.slick-dots li button:before {
    color: #c4c4c4 !important;
    opacity: 1 !important;
    font-size: 12px !important;
}

.slick-prev {
    left: -65px !important;
}

.slick-next {
    right: -65px !important;
}

.toggle_class .css-j2ph2z,
.toggle_class .css-186l2rg {
    border: solid 1.5px red;
    color: red;
    border-radius: 3px;
}

.toggle_class .css-uttm9k {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    border-top: 1.5px solid rgb(255, 17, 17);
}

.toggle_class .css-1hb5ode {
    font-size: 14px;
}

.toggle_class .css-s64al7 .css-uttm9k:first-child {
    border-top: none;
}

.toggle_class .css-17mg6aq:last-of-type {
    border-bottom: 1.5px solid rgb(255, 17, 17);
}

.img_fit img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

@media screen and (min-width: 80em) {

    .work_btn .css-52dxnr[aria-selected=true],
    .work_btn .css-52dxnr[data-selected] {
        width: 13rem !important;
        margin: inherit !important;
        text-align: center !important;
        color: #FF1111 !important;
        margin-top: 1rem !important;
    }

    .work_btn .css-52dxnr[aria-selected=false] {
        display: block !important;
    }
}

@media screen and (min-width: 62em) {

    .work_btn .css-52dxnr[aria-selected=true],
    .work_btn .css-52dxnr[data-selected] {
        width: 13rem !important;
        margin: inherit !important;
        text-align: center !important;
        color: #FF1111 !important;
        margin-top: 1rem !important;
    }

    .work_btn .css-52dxnr[aria-selected=false] {
        display: block !important;
    }

    .work_btn b {
        font-size: 12px;
    }

    .slick-prev,
    .slick-next {
        visibility: visible !important;
    }
}

@media screen and (min-width: 48em) {
    .work_btn b {
        font-size: 16px;
    }

    .work_tabs img {
        height: 450px;
        object-fit: cover;
    }

    .work_btn .css-52dxnr[aria-selected=true],
    .work_btn .css-52dxnr[data-selected] {
        width: 80%;
        margin: auto;
        text-align: center;
        color: #333;
    }

    .work_btn .css-52dxnr[aria-selected=false] {
        display: none;
    }

    .open_header a {
        /* padding-left: 2rem;
        padding-right: 2rem; */
        /* color: #fff; */
        color: #000;
        display: block;
        text-align: end;
        font-family: "Nunito", sans-serif !important;
    }

}

@media screen and (min-width: 30em) {
    .open_header a {
        /* padding-left: 2rem;
        padding-right: 2rem; */
        /* color: #fff; */
        color: #000;
        display: block;
        text-align: end;
        font-family: "Nunito", sans-serif !important;

    }
}

@media screen and (min-width: 0em) {
    .open_header a {
        /* padding-left: 2rem;
        padding-right: 2rem; */
        /* color: #fff; */
        color: #000;
        display: block;
        text-align: end;
        font-family: "Nunito", sans-serif !important;

    }

    .slick-prev,
    .slick-next {
        visibility: hidden;
    }

    .work_btn b {
        font-size: 16px;
    }

    .work_tabs img {
        height: 300px;
        object-fit: cover;
    }

    .work_btn .css-52dxnr[aria-selected=true],
    .work_btn .css-52dxnr[data-selected] {
        width: 80%;
        margin: auto;
        text-align: center;
        color: #333;
    }

    .work_btn .css-52dxnr[aria-selected=false] {
        display: none;
    }

    .Home_tabs_btn .css-1ss3utg {
        padding: 1rem 1rem;
        overflow-wrap: break-word;
    }
}

/* mobile view footer menu */
.navigation {
    position: relative;
    width: 100%;
    height: 70px;
    background: #FF1111;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    box-shadow: 0px -3px 9px rgba(0, 0, 0, 0.25);
}

.navigation ul {
    display: flex;
    width: 85%;
}

.navigation ul li {
    position: relative;
    list-style: none;
    width: 33%;
    height: 70px;
    z-index: 1;
    display: flex;
    align-items: center;
}

.navigation ul .home a {
    top: -50%;
    position: relative;
}

.navigation ul li a {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    font-weight: 500;
    text-align: center;
}

.navigation ul li a .icon {
    position: relative;
    display: block;
    line-height: 75px;
    font-size: 2.5rem;
    text-align: center;
    transition: 0.5s;
    color: #fff;
}

.navigation ul li:active a .icon {
    transform: translateY(-35px);
}

.navigation ul li a .text {
    position: absolute;
    color: aquamarine;
    font-weight: 400;
    font-size: 0.75em;
    letter-spacing: 0.05em;
    transition: 0.5s;
    opacity: 0;
    transform: translateY(20px);
}

.navigation ul li:active a .text {
    opacity: 1;
    transform: translateY(10px);
}

.indicator {
    position: absolute;
    top: -50%;
    width: 70px;
    height: 70px;
    background: #FF1111;
    border-radius: 50%;
    border: solid 6px #fff;
    transform: 0.5s;
}



.Trustus .slick-dots {
    bottom: -50px;
}

.Trustus .slick-dots li button:before {
    color: #c4c4c4 !important;
    opacity: 1 !important;
    font-size: 12px !important;
}

.Trustus .slick-dots li.slick-active button:before {
    color: #FB1923 !important;
}
